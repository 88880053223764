import { Component, OnInit } from '@angular/core';
import { BusinessRulesService } from '../../../Services/businessRules.service';
import { Papa } from 'ngx-papaparse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { checkGroupIdFormat, checkGroupIdNoAdminFormat, checkGuidFormat, checkMailAddressFormat, checkPrimarySMTPDomainFormat, checkLitigationHoldDurationFormat, checkLocalITFormat, checknumberFormat } from '../../../Helpers/inputValidationHelper';
import { RequestContainerService } from '../../../Services/requestContainer.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { UserService } from '../../../Services/user.service';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';
import { GraphApiService } from '../../../Services/graphApi.service';
import { ZscalerApiService } from '../../../Services/zscalerApi.service';
import { OktaApiService } from '../../../Services/oktaApi.service';
import { RequestItemService } from '../../../Services/requestItem.service';
import { RequestItem, RequestItemPreview } from '../../../Models/RequestItem';
import { generateUUID, urlPattern } from '../../../Helpers/utils';

@Component({
    selector: 'BodyContent',
    templateUrl: './csvImportNew.component.html',
    styleUrls: ['../../../app.component.css']
})

export class CsvImportNewComponent implements OnInit {
    public productsDetails = [];
    public localITProductsDetails = [];
    public assignableLicenses = [];
    public selectedProduct = null;
    public fileContentsList = [];
    public csvFileName = "";
    public csvImportErrors = [];
    public csvImportErrorsCollapsed = true;
    public isMultipleProducts = false;
    public _selectedLocalIT = '';
    public _selectedLocalITHistory: String;
    public selectedLocalITQuota = null;
    public quotaExceeded = { Low: 0, High: 0 };
    public currentQuota = null;
    public successWarnings = [];
    public disableSendButton = false;
    public authorizedLocalITs = ["IT_999", "IT_EGS", "IT_WSD"];
    public refreshButtonDisabled = false;
    public getResultsButtonDisabled = false;
    public clearFiltersDisabled = true;
    public csvResults = [];
    mergedWarning = false;

    public page = 1;
    public maxSize = 5;
    public numPages = 1;
    public length = 0;

    public ticketID = '';
    public fromDate = new Date();
    public toDate = new Date();
    private data = [];

    public index = 0;
    public itemsPerPage = 30;
    public order = 'DateCreated DESC'
    public filters = {
        "LocalIT": null,
        "FileName": null,
        "Status": null,
        "Target": null,
        "Product": null,
        "Requestor": null,
        "TicketID": null,
        "fromDate": this.fromDate.toISOString(),
        "toDate": this.toDate.toISOString()
    }
    public rows = [];

    public columns = [
        { title: 'Local IT', name: 'LocalIT', internalName: 'LocalIT', filtering: { filterString: this.filters.LocalIT ? this.filters.LocalIT : "", placeholder: 'LocalIT' }, sort: '', className: 'groupidColumnWidth' },
        { title: 'File name', name: 'FileName', internalName: 'FileName', filtering: { filterString: this.filters.FileName ? this.filters.FileName : "", placeholder: 'FileName filter' }, sort: '', className: 'dateColumnWidth' },
        { title: 'Status', name: 'Status', internalName: 'Status', filtering: { filterString: this.filters.Status ? this.filters.Status : "", placeholder: 'Status Filter' }, sort: '', className: 'statusColumnWidth' },
        { title: 'Items', type: "Number", name: 'Items', internalName: 'Items', sort: '', className: 'groupidColumnWidth' },
        { title: 'Creation Date', type: "Date", name: 'CreatedOn', internalName: 'DateCreated', sort: '', className: 'dateColumnWidth' },
        { title: 'Modification Date', name: 'ModifiedOn', internalName: 'DateModified', sort: '', className: 'dateColumnWidth' },
        { title: 'Requestor', name: 'Requestor', internalName: 'Requestor', filtering: { filterString: this.filters.Requestor ? this.filters.Requestor : "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' }
    ];

    public config: any = {
        paging: true,
        sorting: { columns: this.columns },
        filtering: { filterString: '' },
        className: ['table-striped']
    };
    public inProgress: boolean = false;

    //Handling CSV Import
    private parsingOptions = {
        encoding: "ISO-8859-1",
        delimiter: ";",
        header: true,
        skipEmptyLines: true,
        quoteChar: '"',
        escapeChar: '"',
        beforeFirstChunk: function (chunk) {
            var rows = chunk.split(/\r\n|\r|\n/);
            var headings = rows[0].toLowerCase().trim();
            rows[0] = headings;
            return rows.join("\r\n");
        },
        complete: (results, file) => {
            this.checkImportedData(results.data, file.name);
        }
    };

    constructor(
        private requestContainer: RequestContainerService,
        private requestItemService: RequestItemService,
        private graphApi: GraphApiService,
        private oktaApi: OktaApiService,
        private zscalerApi: ZscalerApiService,
        private userService: UserService,
        private localITsService: LocalITsService,
        private businessRulesService: BusinessRulesService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private csvParser: Papa) { }

    ngOnDestroy() {
    }

    ngOnInit() {
        this.fromDate = new Date(new Date().setDate(new Date().getDate() - 4));
        this.fromDateChanged(this.fromDate);
        this.toDate = new Date();
        this.toDateChanged(this.toDate);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => this.selectedLocalIT = updatedLocalIT);
        this.businessRulesService.getSpecificListByKey('PRODUCTS-DETAILS', 'ProductsDetails').subscribe(response => {
            this.productsDetails = response;
            this.userService.selectedUserLocalITCardObs.subscribe(updatedLocalIT => {
                if (!updatedLocalIT) {
                    this._selectedLocalIT = '';
                    this.clear();
                    return;
                }
                if (!updatedLocalIT.Name) { return; }
                this._selectedLocalIT = updatedLocalIT.Name;
                this.updateProductsList(updatedLocalIT.Products);
                this.localITsService.getLocalITQuotaByName(this._selectedLocalIT).subscribe(response => {
                    this.selectedLocalITQuota = response
                });
            });
        });
        this.businessRulesService.getSpecificListByKey('LICENSESKU', 'AvailableLicenses')
            .subscribe(response => {
                var possibleLicenses = response;
                this.businessRulesService.getSpecificListByKey('LICENSESKU', 'AssignLicense_BusinessLogic')
                    .subscribe(response => {
                        let businessLogic = Object.keys(response);
                        this.assignableLicenses = possibleLicenses.filter(item => businessLogic.includes(item.code));
                    });
            });
    }

    updateProductsList(products) {
        this.localITProductsDetails = [];
        this.productsDetails.forEach(product => {
            if (products[product.ProductName]) {
                this.localITProductsDetails.push(product);
            }
        });
    }

    productSelected(productName) {
        this.selectedProduct = this.localITProductsDetails.find(obj => obj.ProductName === productName);
        productName === "multipleProducts" ? this.isMultipleProducts = true : this.isMultipleProducts = false;
    }

    csvDownload() {
        let propertiesNames = [];
        propertiesNames.push("product");
        this.selectedProduct.Properties.forEach(property => {
            propertiesNames.push(property.Name.toLowerCase());
        });
        propertiesNames.push("ticketid");
        let rows = [propertiesNames];
        rows.push([this.selectedProduct.ProductName]);

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", this.selectedProduct.ProductName + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }

    checkImportedData(data, csvFileName) {
        if (data) {
            let productName = '';
            let zscalerProductsmerged = false;
            for (let i = 0; i < data.length; i++) {
                let errorInData = false;
                let errordata = [];
                let productInfos = null;

                if (data[i]["product"]) {
                    if (productName !== '' && productName !== data[i]["product"].toUpperCase()) {
                        errordata.push("product");
                        errorInData = true;
                    } else {
                        productName = data[i]["product"].toUpperCase()
                    }
                }
                else {
                    if (productName !== '' && productName !== this.selectedProduct.ProductName) {
                        errordata.push("product");
                        errorInData = true;
                    }
                    productName = this.selectedProduct.ProductName;
                }

                if ((productName === "ADD-ZSCALER-TUNNELING" || productName === "DELETE-ZSCALER-TUNNELING") && !errorInData) {
                    if (zscalerProductsmerged) {
                        continue;
                    }
                    let toBemerge = data.filter(x => x.product === productName);

                    if (toBemerge.length > 1) {
                        zscalerProductsmerged = true;
                        this.mergedWarning = true;
                    }
                    if(!urlPattern.test(data[i]["urls"])){
                        errordata.push("urls");
                        errorInData = true;
                    }
                    for (let j = 1; j < toBemerge.length; j++) {
                        if(urlPattern.test(data[i]["urls"]))
                            data[i]["urls"] = data[i]["urls"].concat("," + toBemerge[j]["urls"]);
                        else { 
                            errordata.push("urls");
                            errorInData = true;
                            break;
                        }
                    }
                }
                productInfos = this.localITProductsDetails.find(obj => obj.ProductName === productName);

                if (!(productInfos ? productInfos.Properties ? true : false : false) || errorInData) {
                    errorInData = true;
                }
                else {
                    productInfos.Properties.forEach(property => {
                        let value = data[i][property.Name.toLowerCase()] ? data[i][property.Name.toLowerCase()].trim() : data[i][property.Name.toLowerCase()];
                        if (property.Mandatory && !value) {
                            //error mandatory missing 
                            errorInData = true;
                            errordata.push(property.Name.toLowerCase());
                            return;
                        }
                        else if (!property.Mandatory && (!value || value === '')) {
                            let arraytype = new RegExp('array');

                            if (arraytype.test(property.Type)) {
                                data[i][property.Name.toLowerCase()] = [];
                            }
                            return;
                        }
                        switch (property.Type) {
                            case 'gid':
                                if (!checkGroupIdFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gidNoAdmin':
                                if (!checkGroupIdNoAdminFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'email':
                                if (!checkMailAddressFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'stringarray'://stringarray
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'boolean':
                                if (value.toLowerCase() !== "true" && value.toLowerCase() !== "false" && value.toLowerCase() !== "yes" && value.toLowerCase() !== "no") {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value.toLowerCase() === "true" || value.toLowerCase() === "yes" ? true : false;
                                }
                                break;
                            case 'integer':
                                if (!checknumberFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = parseInt(value);
                                }
                                break;
                            case 'licensesku':
                                if (!this.assignableLicenses.find(v => v.code == value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'localit':
                                if (!checkLocalITFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gid,email':
                                if (!checkGroupIdFormat(value) && !checkMailAddressFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gidNoAdmin,email':
                                if (!checkGroupIdNoAdminFormat(value) && !checkMailAddressFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gid,email,guid':
                                if (!checkGroupIdFormat(value) && !checkMailAddressFormat(value) && !checkGuidFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'gidarray,emailarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'gidNoAdminarray,emailarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        if (array.some(element => !checkGroupIdNoAdminFormat(element) && !checkMailAddressFormat(element))) {
                                            errordata.push(property.Name.toLowerCase());
                                            errorInData = true;
                                        }
                                        else {
                                            data[i][property.Name.toLowerCase()] = array;
                                        }
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'emailarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'gidarray':
                                try {
                                    let array = value.split(',');
                                    if (array.length < 1) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = array;
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'domain':
                                if (!checkPrimarySMTPDomainFormat(value)) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                else {
                                    data[i][property.Name.toLowerCase()] = value;
                                }
                                break;
                            case 'lhduration':
                                try {
                                    if (!checkLitigationHoldDurationFormat(value)) {
                                        errordata.push(property.Name.toLowerCase());
                                        errorInData = true;
                                    }
                                    else {
                                        data[i][property.Name.toLowerCase()] = parseInt(value);
                                    }
                                } catch (error) {
                                    errordata.push(property.Name.toLowerCase());
                                    errorInData = true;
                                }
                                break;
                            case 'phone':
                                break;
                            default:
                                break;
                        }
                    });
                    if (!errorInData) {
                        this.currentQuota[productInfos.QuotaType]--;
                        if (this.currentQuota[productInfos.QuotaType] < 0) {
                            this.quotaExceeded[productInfos.QuotaType]++;
                        }
                    }
                }

                if (!errorInData) {
                    this.fileContentsList.push({ row: data[i], csvLine: i + 2, fileName: csvFileName, productName: productName, properties: productInfos ? productInfos.Properties ? productInfos.Properties : null : null });
                } else {
                    this.csvImportErrors.push({ row: data[i], csvLine: i + 2, error: errordata, fileName: csvFileName, productName: productName, properties: productInfos ? productInfos.Properties ? productInfos.Properties : null : null });
                }
            }
            if (this.fileContentsList.length > 1 && this.fileContentsList.every(item => item.row.product.toUpperCase() == "ADD-DISTRIBUTIONLIST-MEMBERS" && item.row.listid.toUpperCase() == this.fileContentsList[0].row.listid.toUpperCase())) {
                let members = this.fileContentsList.map(item => item.row.members);
                this.fileContentsList = [this.fileContentsList[0]];
                this.fileContentsList[0].row.members = [...new Set([].concat(...members))];
                this.mergedWarning = true
            }
        }
    }

    parseFromCSV(event) {
        this.clear();
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            var files = event.target.files;
            for (let index = 0; index < files.length; index++) {
                this.csvParser.parse(files[index], this.parsingOptions);
            }
            event.target.value = '';
        }
    }

    removeFileContentInErrorFromList(index) {
        this.csvImportErrors.splice(index, 1);
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    handleCollapseBtn(id) {
        if (id) {
            document.getElementById(id).innerHTML = document.getElementById(id).innerHTML == 'chevron_right' ? 'expand_more' : 'chevron_right';
        }
    }

    clear() {
        this.fileContentsList = [];
        this.csvFileName = "";
        this.csvImportErrors = [];
        this.csvImportErrorsCollapsed = true;
        this.successWarnings = [];
        this.mergedWarning = false;
        this.disableSendButton = false;
        this.quotaExceeded = { Low: 0, High: 0 };
        this.currentQuota = { ...this.selectedLocalITQuota };
    }

    goToHelp() {
        window.open("https://engie.sharepoint.com/sites/ensemblesupport/knowledgebase/Pages/" + this.selectedProduct.ProductName + ".aspx", "_blank");
    }

    async sendRequest() {
        this.inProgress = true;
        let itemCount = 0;
        let apiitemcount = 0;
        this.disableSendButton = true;
        //this.fileContentsList.splice(this.localITQuotaLeft, this.fileContentsList.length - this.localITQuotaLeft);
        let guid = generateUUID();
        let requests = [];
        let squidRequests = [];
        for (let index = 0; index < this.fileContentsList.length; index++) {
            const request = this.fileContentsList[index];
            itemCount++;
            let productInfos = null;
            let productName = "";

            if (productName !== request.productName) {
                productName = request.productName;
            }
            productInfos = this.localITProductsDetails.find(obj => obj.ProductName === productName);

            let tempRequest = {};
            request.properties.forEach(property => {
                if (request.row[property.Name.toLowerCase()] || request.row[property.Name.toLowerCase()] === false) {
                    tempRequest[property.Name] = request.row[property.Name.toLowerCase()];
                }
            });
            tempRequest["requestContainerGUID"] = guid;
            if (request.row["ticketid"]) {
                tempRequest["TicketID"] = request.row["ticketid"];
            }
            if (productInfos.ApiFunction) {
                tempRequest["LineIndex"] = itemCount + 1;
                let endpoint = productInfos.ApiFunction.split("/");
                let namespaces = endpoint[0].split(".");
                let func = namespaces.pop();
                if (endpoint.length > 1) {
                    endpoint = `/${endpoint[1]}`;
                    if (squidRequests.filter(item => item?.endpoint == endpoint).length > 0) {
                        squidRequests.filter(item => item?.endpoint == endpoint).map(req => req.requests.push(tempRequest));
                    }
                    else {
                        squidRequests.push({'requests': [tempRequest], 'endpoint': endpoint, 'func': func, 'namespaces': namespaces})
                    }
                }
                else {
                    endpoint = null;
                    tempRequest["localIT"] = this._selectedLocalIT;
                }
                let currentCtx = this;
                if (squidRequests.length > 0 && index == this.fileContentsList.length -1) {
                    for (var j = 0; j < squidRequests.length; j++) {
                        for (var i = 0; i < squidRequests[j].namespaces.length; i++) {
                            currentCtx = currentCtx[squidRequests[j].namespaces[i]];
                        }
                        currentCtx[squidRequests[j].func].apply(currentCtx, [{requests: squidRequests[j].requests,localIT: this._selectedLocalIT},squidRequests[j].endpoint]).subscribe();
                    }
                }
                if (!endpoint) {
                    apiitemcount++;
                    for (var i = 0; i < namespaces.length; i++) {
                        currentCtx = currentCtx[namespaces[i]];
                    }
                    currentCtx[func].apply(currentCtx, [tempRequest]).subscribe();
                }
            }
            else {
                tempRequest["Product"] = request.productName;
                requests.push(tempRequest);
            }
        }

        var data = {
            requests: requests,
            localIT: this._selectedLocalIT,
            fileName: this.fileContentsList[0]["fileName"],
            requestContainerGUID: guid,
            items: itemCount
        }
        if (itemCount === apiitemcount) {
            data["status"] = 'Processed';
        } else if (requests.length < 1) {
            data["status"] = 'InProcess';
        }

        this.requestContainer.createRequestContainer(data)
            .subscribe(response => {
                this.inProgress = false;
                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });
                this.successWarnings.push("Your requests are successfully sent.");
            },
                error => {
                    this.inProgress = false;
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
        this.fileContentsList = [];
        this.csvImportErrors = [];
    }

    fromDateChanged(newValue) {
        var tempDate = new Date(newValue);
        if (!isNaN(tempDate.valueOf())) {
            tempDate = new Date(tempDate.setHours(0, 0, 0, 0));
        }
        this.fromDate = tempDate;
    }

    toDateChanged(newValue) {
        var tempDate = new Date(newValue);
        if (!isNaN(tempDate.valueOf())) {
            tempDate = new Date(tempDate.setHours(23, 59, 59, 999));
        }
        this.toDate = tempDate;
    }

    //Mappeur
    mapRequestItemToRequestItemPreview(requestItems: RequestItem[]): Array<any> {
        let requestItemPreviewList = new Array<RequestItemPreview>();
        requestItems.forEach(element => {
            let requestItemPreview = new RequestItemPreview();

            requestItemPreview._key = element._key ? element._key : '';
            requestItemPreview.GUID = element.GUID ? element.GUID : '';
            requestItemPreview["Items"] = element.Items ? element.Items : '';
            requestItemPreview.Requestor = element.Requestor ? element.Requestor : '';
            requestItemPreview.Status = element.Status ? element.Status : '';
            requestItemPreview.CreatedOn = element.DateCreated ? new Date(element.DateCreated).toLocaleString(navigator.language) : '';
            requestItemPreview.CreatedOnDate = element.DateCreated;
            requestItemPreview.ModifiedOn = element.DateModified ? new Date(element.DateModified).toLocaleString(navigator.language) : '';
            requestItemPreview.ModifiedOnDate = element.DateModified;
            requestItemPreview.LocalIT = element.LocalIT ? element.LocalIT : '';
            requestItemPreview["FileName"] = element["FileName"] ? element["FileName"] : '';

            requestItemPreviewList.push(requestItemPreview);
        });
        return requestItemPreviewList;
    }

    refreshData(disableButton?) {
        this.getFilters();
        if (this._selectedLocalITHistory) {
            this.inProgress = true;
            this.refreshButtonDisabled = true;
            this.requestItemService.getRequestItemsCount(this._selectedLocalITHistory, this.filters, this.index, this.itemsPerPage, this.order, "true").subscribe(response => { this.length = response ? response : 0; });
            this.requestItemService.getRequestItems(this._selectedLocalITHistory, this.filters, this.index, this.itemsPerPage, this.order, "true").subscribe(response => { this.data = this.mapRequestItemToRequestItemPreview(response); this.rows = this.data; this.inProgress = false; this.refreshButtonDisabled = false; });

            if (disableButton) {
                //this.disableRefreshBtn();
            }
        }
    }

    getResults(containerID) {
        this.getResultsButtonDisabled = true;
        let csvContent = "";
        this.requestItemService.getRequestItems(this._selectedLocalITHistory, { RequestContainerGUID: containerID }, 0, 10000, 'DateModified').subscribe(response => {
            this.csvResults = response;
            let row = "Line;Status;Subject;Product;Created;Modified;History";
            csvContent += row + ";\n";
            this.csvResults.forEach(function (item) {
                row = item["LineIndex"] + ";" + item["Status"] + ";" + (item.ScopeValidation ? item["ScopeValidation"]["FeedbackSubject"] : "") + ";" + item["ProductName"] + ";" + item["DateCreated"] + ";" + item["DateModified"];
                item.History.forEach((history) => {
                    if (!history.Audience || history?.Audience === "LocalIT") {
                        row += ";\"" + history.Message + "\"";
                    }
                });
                csvContent += row + "\n";
            });
            let uri = `data:text/csv;charset=utf-8;header=present,${encodeURIComponent(csvContent)}`;
            let link = document.createElement('a');
            link.setAttribute('href', uri);
            link.setAttribute('download', `${containerID}.csv`);
            link.addEventListener('click', () => link.parentNode.removeChild(link));
            document.body.appendChild(link);

            link.click();
        });

        setTimeout(() => {
            this.getResultsButtonDisabled = false;
        }, 2000);
    }

    public getFilters() {
        if (this.config) {
            this.filters.LocalIT = this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString;
            this.filters.FileName = this.config.sorting.columns.find(filter => filter.internalName === "FileName").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "FileName").filtering.filterString;
            this.filters.Status = this.config.sorting.columns.find(filter => filter.internalName === "Status").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Status").filtering.filterString;
            this.filters.Requestor = this.config.sorting.columns.find(filter => filter.internalName === "Requestor").filtering.filterString === "" ? null : this.config.sorting.columns.find(filter => filter.internalName === "Requestor").filtering.filterString;
            this.filters.TicketID = this.ticketID;
            this.filters.fromDate = this.fromDate.toISOString();
            this.filters.toDate = this.toDate.toISOString();
        }
        var sorted = this.config.sorting.columns.find(column => column.sort != "");
        this.order = sorted ? sorted.internalName + " " + sorted.sort : this.order;
        if (this.filters.LocalIT == null && this.filters.Product == null && this.filters.Status == null && this.filters.Target == null && this.filters.Requestor == null && this.filters.TicketID == null) {
            this.clearFiltersDisabled = true;
        }
        else {
            this.clearFiltersDisabled = false;
        }
    }

    clearFilters() {
        this.columns = [
            { title: 'Local IT', name: 'LocalIT', internalName: 'LocalIT', filtering: { filterString: "", placeholder: 'LocalIT' }, sort: '', className: 'groupidColumnWidth' },
            { title: 'File name', name: 'FileName', internalName: 'FileName', filtering: { filterString: this.filters.FileName ? this.filters.FileName : "", placeholder: 'FileName filter' }, sort: '', className: 'dateColumnWidth' },
            { title: 'Status', name: 'Status', internalName: 'Status', filtering: { filterString: "", placeholder: 'Status Filter' }, sort: '', className: 'statusColumnWidth' },
            { title: 'Items', type: "Number", name: 'Items', internalName: 'Items', sort: '', className: 'groupidColumnWidth' },
            { title: 'Creation Date', type: "Date", name: 'CreatedOn', internalName: 'DateCreated', sort: '', className: 'dateColumnWidth' },
            { title: 'Modification Date', name: 'ModifiedOn', internalName: 'DateModified', sort: '', className: 'dateColumnWidth' },
            { title: 'Requestor', name: 'Requestor', internalName: 'Requestor', filtering: { filterString: "", placeholder: 'GID Filter' }, sort: '', className: 'groupidColumnWidth' }
        ];
        this.filters = {
            "LocalIT": null,
            "FileName": null,
            "Status": null,
            "Target": null,
            "Product": null,
            "Requestor": null,
            "TicketID": null,
            "fromDate": this.fromDate.toISOString(),
            "toDate": this.toDate.toISOString()
        }
        this.ticketID = null;
        this.config = {
            paging: true,
            sorting: { columns: this.columns },
            filtering: { filterString: '' },
            className: ['table-striped']
        };
        if (this._selectedLocalITHistory == 'SHOW ALL') {
            //Add LocalIT column for SHOW ALL mode
            this.config.className = 'table-striped';
        } else {
            //Remove LocalIT column for standard mode
            this.config.className = 'table-striped localit-hidden';
        }
        this.refreshData();
    }
    public changePage(event) {
        if (event != null) {
            this.index = (event.page - 1) * this.itemsPerPage;
        }
        this.onChangeTable();
    }

    public onChangeTable(event = null) {
        this.inProgress = true;
        this.getFilters();
        this.requestItemService.getRequestItemsCount(this._selectedLocalITHistory, this.filters, this.index, this.itemsPerPage, this.order, "true").subscribe(response => { this.length = response ? response : 0; });
        this.requestItemService.getRequestItems(this._selectedLocalITHistory, this.filters, this.index, this.itemsPerPage, this.order, "true").subscribe(response => { this.data = this.mapRequestItemToRequestItemPreview(response); this.rows = this.data; this.inProgress = false; });
    }

    disableRefreshBtn() {
        this.refreshButtonDisabled = true;
        setTimeout(() => { this.refreshButtonDisabled = false }, 2000);
    }

    get selectedLocalIT() {
        return this._selectedLocalITHistory;
    }

    set selectedLocalIT(selectedLocalITHistory: String) {
        this._selectedLocalITHistory = selectedLocalITHistory;
        if (this._selectedLocalITHistory == 'SHOW ALL') {
            //Add LocalIT column for SHOW ALL mode
            this.config.className = 'table-striped';
        } else {
            //Remove LocalIT column for standard mode
            this.config.className = 'table-striped localit-hidden';
            this.filters.LocalIT = null;
            this.config.sorting.columns.find(filter => filter.internalName === "LocalIT").filtering.filterString = "";
        }
        this.refreshData();
    }

}