<h4>Reset Okta B2B User Mfa:</h4>
<br /><br />
<h5 class="text-warning"><b>Before</b> launching the MFA reset, please confirm the user identity, through Wipass SD or after manager's approval.</h5>
<br /><br />
<form #fileContentForm="ngForm" (ngSubmit)="fileContentForm.resetForm()" >
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupIDInput">Group ID:</label>
            <div style="display:flex">
                <input type="text" id="groupIDInput" name="groupIDInput" class="form-control"
                        [(ngModel)]="fileContent.GroupID"
                        required [pattern]="groupIDPattern"/>
                <i class="material-icons md-36 primaryBlue pointer" aria-placeholder="Search" style="margin-left: 5px;" (click)="getUserInOkta();">search</i>
            </div>
            <span class="badge badge-info">{{userNotFound}}</span> 
        </div>
        <div class="col-4">
            <label class="required" for="oktaMfaPlatformInput">Platform:</label>
            <select id="oktaMfaPlatformInput" class="form-control"
                [(ngModel)]="fileContent.Platform" required name="oktaMfaPlatformInput" #oktaMfaPlatformInput="ngModel">
                <option *ngFor="let platfom of oktaMfaPlatforms | orderby: 'DisplayName' : false : true" [value]="platfom.InternalName">{{platfom.DisplayName}}</option>
            </select>        
            <small *ngIf="oktaMfaPlatformInput.value == 'All'" id="allselected" class="form-text text-danger">Are you sure you want to reset ALL MFA Factors for this user ?</small>
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                    [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-2 offset-8" style="padding-top:33px;">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(fileContentForm.invalid); fileContentForm.reset();" [disabled]="fileContentForm.invalid">Send Request</button>
        </div>
    </div>
</form>