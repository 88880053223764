export enum RequestStatus {
    '' = 0,
    'Processed',
    'ReadyToProcess',
    'ValidFile',
    'Downloaded',
    'Loaded',
    'Expired',
    'InvalidFileContent',
    'InvalidFileType',
    'Invalid',
    'Success',
    'Failure',
    'Running',
    'Warning',
    'Error',
    'MaxRetriesReached',
    'Disabled',
    'PendingActivation',
    'PendingProvisioning',
    'MaxWaitReached',
    'Information',
    'InProcess'
}

export enum ProductsName {
    '' = 0,
    'Add-Okta-User-To-Group',
    'Remove-Okta-User-From-Group',
    'Create-Okta-CloudAccount',
    'Expire-Okta-CloudAccount-Password',
    'Reset-Okta-User-Mfa',
    'Add-DistributionList-Members',
    'Add-Mailbox-FullAccess',
    'Add-Mailbox-SendAs',
    'Create-PersonalMailboxO365',
    'Enable-MobileDevice',
    'Get-MobileDevice-Info',
    'Remove-DistributionList-Members',
    'Get-User-Info',
    'Assign-License',
    'Modify-License-Option',
    'Remove-License',
    'Enable-S4B-IntercallDialinConferencing',
    'Modify-S4B-ConferencingPolicy'
}

export enum Brands {
    '' = 0,
    'Engie S.A.',
    'BrandTest1',
    'BrandTest2',
    'BrandTest3',
    'BrandTest4',
    'BrandTest5',
    'BrandTest6',
}

export enum Disclaimers {
    '' = 0,
    'Disclaimer-Engie_FR',
    'Disclaimer-Engie_EN',
    'Disclaimer-Engie_DE',
    'Disclaimer-Engie_IT',
    'Disclaimer-Engie_ES',
    'Disclaimer-Engie_NL',
    'Disclaimer-Engie_HU',
    'Disclaimer-Engie_PT'
}

export enum Languages {
    '' = 0,
    'fr-FR',
    'fr-BE',
    'en-US',
    'es-ES',
    'zh-CN'
}

export enum LicenseTypes {
    '' = 0,
    'STANDARDPACK',
    'ENTERPRISEPACK'
}

export enum MailboxLicenseTypes {
    '' = 0,
    'STANDARDPACK',
    'ENTERPRISEPACK'
}

export enum OktaMfaProviders {
    'ALL' = 0,
    'OKTA',
    'GOOGLE'
}