import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { GraphApiService } from '../../../Services/graphApi.service';
import { LocalITsService } from '../../../Services/localITs.service';
import { WebAdministrationService } from '../../../Services/webAdministration.service';
import { CreateAADGroupFileContent, CreateAADGroupRequest } from '../../../Models/FileContents';

import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';


import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';

@Component({
    selector: 'BodyContent',
    templateUrl: './createAADGroup.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class CreateAADGroupComponent {
    
    
    

    public fileContentsList = new Array<CreateAADGroupFileContent>();
    public fileContent = new CreateAADGroupFileContent();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public Entities:Array<object> = new Array<object>();
    public topLocalIT:Array<string> =  new Array<string>();
    public entitiesError: String = '';
    public groupIDPattern: string = inputValidationHelper.groupIdFormatRegEx;
    public groupDisplayNamePattern: string = inputValidationHelper.groupDisplayNameRegEx;
    public groupDescriptionPattern: string = inputValidationHelper.groupDescriptionRegEx;

    public testUserProfile: any = null;

    constructor(private requestContainerService: RequestContainerService, private userService: UserService, private graphApiService: GraphApiService, private webAdministrationService : WebAdministrationService, private localITsService: LocalITsService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.localITsService.getLocalITsWithManageAllPermission().subscribe( response => {
            this.topLocalIT = response;
            this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
                this.selectedLocalIT = updatedLocalIT;
                this.entitiesError = '';
                this.fileContent.OrgID = '';
                if (this.topLocalIT.indexOf(this.selectedLocalIT as string) !== -1) {
                    this.webAdministrationService.getEntities({ Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                        this.Entities = entity?.body;
                        this.Entities = this.Entities.filter(item => item["OrgID"].match(/ORG\d{4}/i));
                    },
                    (error) =>  {
                        this.Entities = [];
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                    });
                }
                else {
                    this.webAdministrationService.getEntities({ ITCard: this.selectedLocalIT, Status: "Valid", itemPerPage: 5000 }).subscribe((entity: any) => {
                        this.Entities = entity?.body;
                        this.Entities = this.Entities.filter(item => item["OrgID"].match(/ORG\d{4}/i));
                        if (this.Entities.length == 0) {
                            this.entitiesError = `Make sure ITCard ${this.selectedLocalIT} is added in Org-IT for the entities ${this.selectedLocalIT} is allowed to manage.`
                        }
                        else {
                            this.fileContent.OrgID = this.Entities[0]["OrgID"]
                        }
                    },
                    (error) =>  {
                        this.Entities = [];
                        this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                    });
                }
            });
        });
        this.fileContent.GroupPurpose = "general";
    }

    ngOnDestroy() {
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<CreateAADGroupFileContent>();
    }

    clearFileContentForms() {
        this.fileContent = new CreateAADGroupFileContent();
        this.fileContent.GroupPurpose = "Intune Application";
    }

    handleCollapseBtn(id) {
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    addAdditionalGroupOwner() {
        this.fileContent.GroupOwners.push('');
    }

    removeAdditionalGroupOwner(index) {
        this.fileContent.GroupOwners.splice(index, 1);
    }

    addFileContentToList() {
        var tempObject = new CreateAADGroupFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    removeFileContentFromList(index) {
        this.fileContentsList.splice(index, 1);
    }

    handlePurposeChange(purpose) {
        if (purpose == 'intune') {
            this.fileContent.WorkplaceType = 'MDM';
        }
        else {
            this.fileContent.WorkplaceType = '';
        }
    }

    /*sendRequest(isFormInvalid?){
        if(isFormInvalid == false){
            this.addFileContentToList();
        }
        
        var data = new CreateAADGroupRequest();
        data.requests = this.fileContentsList;
        data.localIT = this.selectedLocalIT;
        
        this.requestContainerService.sendProductRequest(data, '/createAADGroup')
            .subscribe( response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],                  
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            }, 
            error => {
                this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData : error } });
            }
        );        
    }*/

    sendRequest() {
        let users = [];
        this.fileContent.GroupOwners.forEach(user => {
            if (user) {
                users.push(`${user}`);
            }
        });
        let data = { ...this.fileContent };
        data["localIT"] = this.selectedLocalIT;
        data["GroupOwners"] = users;

        //call the post graph api
        this.graphApiService.createAADGroup(data)
            .subscribe(
                response => {
                    this.clearPage();

                    let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                        duration: 2000,
                        verticalPosition: "bottom",
                        horizontalPosition: "right",
                        panelClass: ['bg-success', 'font-weight-bold'],
                    });

                    successSnackBar.afterDismissed().subscribe(null, null, () => {
                        this.router.navigate(['/history']);
                    })
                },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }
}