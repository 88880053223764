<h4>Create AAD Group:</h4>

<form #fileContentForm="ngForm">
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupDisplayNameInput">Group Display Name:</label>
            <input type="text" id="groupDisplayNameInput" class="form-control" [pattern]="groupDisplayNamePattern"
                [(ngModel)]="fileContent.GroupDisplayName" name="groupDisplayNameInput" required #groupDisplayNameInput="ngModel">
            <div *ngIf="groupDisplayNameInput.invalid && (groupDisplayNameInput.dirty || groupDisplayNameInput.touched)" class="alert alert-danger">
                <div *ngIf="groupDisplayNameInput.errors.required">
                    Name is required.
                </div>
                <div *ngIf="groupDisplayNameInput.errors.pattern">
                    Name contains an invalid character.
                </div>
            </div>
        </div>
        <div class="col-4">
            <label class="required" for="groupPurposeInputOpt1">Group Purpose:</label>
            <div>
                <input type="radio" id="groupPurposeInputOpt1" name="groupPurposeInput" (change)="handlePurposeChange($event.currentTarget.value);" value="general" [(ngModel)]="fileContent.GroupPurpose" #groupPurposeInputOpt1="ngModel">General Security Group<br>
            </div>  
            <div>
                <input type="radio" id="groupPurposeInputOpt2" name="groupPurposeInput" (change)="handlePurposeChange($event.currentTarget.value);" value="intune" [(ngModel)]="fileContent.GroupPurpose" #groupPurposeInputOpt2="ngModel">Intune Application<br>
            </div>  
            <div>
                <input type="radio" id="groupPurposeInputOpt3" name="groupPurposeInput" (change)="handlePurposeChange($event.currentTarget.value);" value="protected" [(ngModel)]="fileContent.GroupPurpose" #groupPurposeInputOpt2="ngModel">Protected Security Group<br>
            </div> 
        </div>
        <div class="col-4">
            <label class="required" for="orgIDDropdownList">OrgID:</label>
            <select id="orgIDDropdownList" class="form-control"
                [(ngModel)]="fileContent.OrgID" name="orgIDDropdownList"
                required #orgIDDropdownList="ngModel">
                <option selected="selected"></option>
                <option *ngFor="let entity of Entities | orderby: 'OrgID' : false : true" [value]="entity.OrgID" title="{{entity.Name}}">{{entity.OrgID}}</option>
            </select>
            <div *ngIf="orgIDDropdownList.invalid && (orgIDDropdownList.dirty || orgIDDropdownList.touched)" class="alert alert-danger">
                <div *ngIf="orgIDDropdownList.errors.required">
                    OrgID is required.
                </div>
            </div>
            <div *ngIf="entitiesError" class="alert alert-danger">
                {{entitiesError}}
            </div>
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">            
            <label class="required" for="additionalGroupOwnerInput0">Group Owners:</label>
            <div *ngFor="let additionalGroupOwner of fileContent.GroupOwners; let i = index; first as isFirst; trackBy:trackByIndex">
                <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'margin-top': isFirst ? '0px': '5px'}">
                    <input type="text" id="{{'additionalGroupOwnerInput' + i}}" class="form-control col-10"
                        [(ngModel)]="fileContent.GroupOwners[i]" name="{{'additionalGroupOwnerInput' + i}}"
                        [ngClass]="{'has-danger-forced':(additionalGroupOwnerInput.invalid && (additionalGroupOwnerInput.dirty || additionalGroupOwnerInput.touched || additionalGroupOwnerInput.value))}"
                        [pattern]="groupIDPattern" required #additionalGroupOwnerInput="ngModel">
                    <i *ngIf="isFirst" id="additionalGroupOwnerInputAddBtn" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="addAdditionalGroupOwner()">add_circle_outline</i>
                    <i *ngIf="!isFirst" id="{{'additionalGroupOwnerInputRemoveBtn' + i}}" class="material-icons md-36 primaryBlue pointer" style="margin-left: 5px;" (click)="removeAdditionalGroupOwner(i)">remove_circle_outline</i>
                    <div *ngIf="additionalGroupOwnerInput.invalid && (additionalGroupOwnerInput.dirty || additionalGroupOwnerInput.touched)" class="alert alert-danger">
                        <div class="break"></div>
                        <div *ngIf="additionalGroupOwnerInput.errors.required">
                            Group ID is required.
                        </div>
                        <div *ngIf="additionalGroupOwnerInput.errors.pattern">
                            Group ID format is invalid.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4" *ngIf="fileContent.GroupPurpose =='intune'">
            <label class="required" for="typeInputOpt1">Type:</label>
            <div>
                <input type="radio" id="typeInputOpt1" name="typeInput" value="MAC" [(ngModel)]="fileContent.WorkplaceType" #typeInputOpt1="ngModel">MAC<br>
            </div>  
            <div>
                <input type="radio" id="typeInputOpt2" name="typeInput" value="MDM" [(ngModel)]="fileContent.WorkplaceType" #typeInputOpt2="ngModel">MDM<br>
            </div>  
            <div>
                <input type="radio" id="typeInputOpt3" name="typeInput" value="WIN" [(ngModel)]="fileContent.WorkplaceType" #typeInputOpt2="ngModel">WIN<br>
            </div> 
        </div>
        <div class="col-4">
            <label for="ticketIDInput">Ticket ID:</label>
            <input type="text" id="ticketIDInput" name="ticketIDInput" class="form-control"
                [(ngModel)]="fileContent.TicketID" #ticketIDInput="ngModel">
        </div>
    </div>
    <div class="row form-group">
        <div class="col-4">
            <label class="required" for="groupDescriptionInput">Group Description:</label>
            <input type="text" id="groupDescriptionInput" class="form-control" [pattern]="groupDescriptionPattern"
                [(ngModel)]="fileContent.GroupDescription" name="groupDescriptionInput" required #groupDescriptionInput="ngModel">
            <div *ngIf="groupDescriptionInput.invalid && (groupDescriptionInput.dirty || groupDescriptionInput.touched)" class="alert alert-danger">
                <div *ngIf="groupDescriptionInput.errors.required">
                    Description is required.
                </div>
                <div *ngIf="groupDescriptionInput.errors.pattern">
                    Description contains an invalid character.
                </div>
            </div>
        </div>
        <div class="col-8">
            <span *ngIf="fileContent.GroupPurpose == 'general'" style="color:grey;font-weight:bold;font-style:italic">
                For General Security Groups, the naming convention is &lt;ORGXXXX&gt;-SG-&lt;Group Display Name&gt;, just enter the &lt;Group Display Name&gt; part. The prefix {{fileContent.OrgID}}-SG- will be automatically added.
            </span>
            <span *ngIf="fileContent.GroupPurpose == 'intune'" style="color:grey;font-weight:bold;font-style:italic">
                For Intune Application groups, the naming convention is &lt;ORGXXXX&gt;-SG-&lt;Type&gt;-Local-&lt;Group Display Name&gt;, just enter the &lt;Group Display Name&gt; part where &lt;Group Display Name&gt; is the Intune Application Name. The prefix {{fileContent.OrgID}}-SG-{{fileContent.WorkplaceType}}-Local- will be automatically added.
            </span>
            <span *ngIf="fileContent.GroupPurpose == 'protected'" style="color:grey;font-weight:bold;font-style:italic">
                For Protected Security Groups, the naming convention is &lt;ORGXXXX&gt;-SG-PROTECTED&lt;Group Display Name&gt;, just enter the &lt;Group Display Name&gt; part. The prefix {{fileContent.OrgID}}-SG-PROTECTED- will be automatically added.
            </span>
        </div>
    </div>
    <div class="row form-group">                

        <div class="col-1 offset-8" style="padding-top:33px">
            <button type="submit" class="btn btn-primary" (click)="sendRequest(); fileContentForm.reset();" [disabled]="(fileContentForm.invalid)">Send Request</button>
        </div>
    </div>
</form>