export const booleanUpperCaseValues:Array<string> = ['TRUE', 'FALSE', 'YES', 'NO'];
export const countryCodeRegEx = '^[a-zA-Z]{2}$';
export const groupIdAdminFormatRegEx = '^([a-zA-Z]{2}[0-9]{4})(-[a-zA-Z])$|^([a-zA-Z]{3}[0-9]{3})(-[a-zA-Z])$|^([a-zA-Z]{4}[0-9]{2})(-[a-zA-Z])$';
export const groupIdFormatRegEx = '^([a-zA-Z]{2}[0-9]{4})(-[a-zA-Z])?$|^([a-zA-Z]{3}[0-9]{3})(-[a-zA-Z])?$|^([a-zA-Z]{4}[0-9]{2})(-[a-zA-Z])?$';
export const groupIdNoAdminFormatRegEx = '^[a-zA-Z]{2}[0-9]{4}$|^[a-zA-Z]{3}[0-9]{3}$|^[a-zA-Z]{4}[0-9]{2}$';
export const mailFormatRegEx = '^[a-zA-Z0-9]+[a-zA-Z0-9._-]*[a-zA-Z0-9]+@[a-zA-Z0-9]+([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$';
export const upnFormatRegEx = '^[a-zA-Z0-9]+[a-zA-Z0-9._-]*[a-zA-Z0-9#]+@[a-zA-Z0-9]+([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$';
export const emailAddressFormatRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
export const guidFormatRegEx = '^[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$';
export const nameRegEx = '^[^/\\\\@+?]+$';
export const primarySMTPFormatRegEx = '';
export const primarySMTPDomainFormatRegEx = '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$';
export const litigationHoldDurationRegEx = '^(10|7){1}$'
export const localITRegEx = '^IT_([0-9]{3}|EGS|WSD)$'
export const numberRegEx = '^([0-9])+$'
export const recordNameRegEx = '^(?![0-9]+$)(?![-\.])[a-zA-Z0-9-_\.]{0,63}(?![-_\.])$'
export const siteRegEx = /(^((www.)|(\.))+([a-zA-Z0-9-_])+\.[^\s]+$)|(^([a-zA-Z0-9-_])+\.[^\s]+$)/gi;
export const mailboxNameRegEx = '^((?![^\\w\\s-\.À-ž&()]+|_).)*$';
export const groupDisplayNameRegEx = '^[^\\\\!#$%&*+/=?^`{}À-ž]*$';
export const groupDescriptionRegEx = '^[^\\\\!#$%&*+/=?^`{}]*$';
export const orgIDRegex = '^ORG\\d{4}$';

export function checkGroupIdFormat(input){
    var regex = new RegExp(groupIdFormatRegEx);
    return regex.test(input);
}

export function checkGroupIdAdminFormat(input){
    var regex = new RegExp(groupIdAdminFormatRegEx);
    return regex.test(input);
}

export function checkGroupIdNoAdminFormat(input){
    var regex = new RegExp(groupIdNoAdminFormatRegEx);
    return regex.test(input);
}

export function checkGuidFormat(input){
    var regex = new RegExp(guidFormatRegEx);
    return regex.test(input);
}

export function checkMailFormat(input){
    var regex = new RegExp(mailFormatRegEx);
    return regex.test(input);
}

export function checkPrimarySMTPFormat(input){
    return true;
    //var regex = new RegExp(primarySMTPFormatRegEx);
    //return regex.test(input);
}

export function checkPrimarySMTPDomainFormat(input){
    var regex = new RegExp(primarySMTPDomainFormatRegEx);
    return regex.test(input);
}

export function checkMailAddressFormat(input){
    var regex = new RegExp(emailAddressFormatRegEx);
    return regex.test(input);
}

export function checkInitialsFormat(input){
    return input.length <= 2;
}

export function checkBooleanFormat(input){
    var upperCaseInput = input.toUpperCase();
    if(booleanUpperCaseValues.indexOf(upperCaseInput) >= 0){
        return true;
    }else{
        return false;
    }
}

export function checkLitigationHoldDurationFormat(input){
    var regex = new RegExp(litigationHoldDurationRegEx);
    return regex.test(input);
}

export function checkLocalITFormat(input){
    var regex = new RegExp(localITRegEx);
    return regex.test(input);
}

export function checknumberFormat(input){
    var regex = new RegExp(numberRegEx);
    return regex.test(input);
}

export function checksiteFormat(input){
    var regex = new RegExp(siteRegEx);
    return regex.test(input);
}