import { Component } from '@angular/core';
import { Router } from '@angular/router'

import { RequestContainerService } from '../../../Services/requestContainer.service';
import { ResetOktaB2BUserMfaFileContent } from '../../../Models/FileContents';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RequestErrorDialog } from '../../Template/Dialog/requestErrorDialog.component';

import { BusinessRulesService } from '../../../Services/businessRules.service';
import { UserInfo } from '../../../Models/UserInfo';
import { UserService } from '../../../Services/user.service';
import { OktaApiService } from '../../../Services/oktaApi.service';



import * as inputValidationHelper from '../../../Helpers/inputValidationHelper';
import { ModifyRequest } from '../../../Helpers/modifyRequest';


@Component({
    selector: 'BodyContent',
    templateUrl: './resetOktaB2BUserMfa.component.html',
    providers: [RequestContainerService],
    styleUrls: ['../../../app.component.css']
})

export class ResetOktaB2BUserMfaComponent {
    
    
    
    

    public fileContentsList = new Array<ResetOktaB2BUserMfaFileContent>();
    public fileContent = new ResetOktaB2BUserMfaFileContent();
    public currentUserInfo: UserInfo = new UserInfo();
    public selectedLocalIT: String = '';
    public userNotFound: String = '';
    public oktaMfaPlatforms: Array<string> = new Array<string>();

    public groupIDPattern: string = inputValidationHelper.groupIdFormatRegEx;

    constructor(private requestContainerService: RequestContainerService, private oktaApiService: OktaApiService, private userService: UserService, private businessRulesService: BusinessRulesService, public snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private modifyRequest: ModifyRequest) {
        this.clearFileContentForms();
    }

    ngOnInit() {
        this.userService.currentUserInfoObs.subscribe(updatedUserInfo => this.currentUserInfo = updatedUserInfo);
        this.userService.selectedUserLocalITObs.subscribe(updatedLocalIT => {
            this.selectedLocalIT = updatedLocalIT;
            this.modifyRequest.getRequestParameters(this.fileContent, this.selectedLocalIT);
        });

        this.businessRulesService.getSpecificListByKey('OKTAMFAPROVIDERS', 'AvailableOktaMfaPlatforms')
            .subscribe(response => { this.oktaMfaPlatforms = response; });
    }

    ngOnDestroy() {
        
        
        
    }

    trackByIndex(index: number, value: number) {
        return index;
    }

    clearPage() {
        this.clearFileContentForms();
        this.fileContentsList = new Array<ResetOktaB2BUserMfaFileContent>();
    }

    clearFileContentForms() {
        this.fileContent.GroupID = '';
        this.fileContent.OktaMfaProvider = this.oktaMfaPlatforms[0];
    }

    handleCollapseBtn(id) {
        document.getElementById(id).setAttribute('src', document.getElementById(id).getAttribute('src') == '../../assets/images/16x16_expand.png' ? '../../assets/images/16x16_expanded.png' : '../../assets/images/16x16_expand.png');
    }

    //Add the current fileContent to the fileContentList and clean the forms (Need reflexion for the Edit)
    addFileContentToList() {
        var tempObject = new ResetOktaB2BUserMfaFileContent(this.fileContent);
        this.fileContentsList.push(tempObject);
        this.clearFileContentForms();
    }

    sendRequest(isFormInvalid?) {
        if (isFormInvalid == false) {
            this.addFileContentToList();
        }

        var data = {};
        data["requests"] = this.fileContentsList;
        data["localIT"] = this.selectedLocalIT;

        this.requestContainerService.sendProductRequest(data, '/resetOktaUserMfa')
            .subscribe(response => {
                this.clearPage();

                let successSnackBar = this.snackBar.open("REQUEST SENT !", null, {
                    duration: 2000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: ['bg-success', 'font-weight-bold'],
                });

                successSnackBar.afterDismissed().subscribe(null, null, () => {
                    this.router.navigate(['/history']);
                })
            },
                error => {
                    this.dialog.open(RequestErrorDialog, { width: 'auto', height: 'auto', data: { errorData: error } });
                }
            );
    }

    getUserInOkta() {
        let gID = this.fileContent.GroupID.toString();
        if (gID) {
            this.oktaApiService.getOktaB2BUserByGroupID(gID)
                .subscribe(
                    response => {
                        this.userNotFound = `${response.displayName}`
                    },
                    (error) => {
                        if (error && error.status && error.status == '404') {
                            this.userNotFound = `${gID} not found in Okta!`;
                        } else {
                            this.userNotFound = `Error when trying to search ${gID} in Okta!`;
                        }
                    }
                );
        }
    }
}