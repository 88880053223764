import { RequestStatus } from './enums';

export function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};

export function booleanToStringConverter(booleanToConvert: boolean, optionalFalseString?: string, optionalTrueString?: string): string {
    let booleanString: string = "";
    if (booleanToConvert == false) {
        booleanString = optionalFalseString ? optionalFalseString : 'False';
    } else if (booleanToConvert == true) {
        booleanString = optionalTrueString ? optionalTrueString : 'True';
    }
    return booleanString;
};

export function stringToBooleanConverter(inputString) {
    var upperCaseInputString = inputString.toUpperCase();
    if (upperCaseInputString == 'TRUE' || upperCaseInputString == 'YES') {
        return true;
    } else if (upperCaseInputString == 'FALSE' || upperCaseInputString == 'NO') {
        return false
    } else {
        //Shoud return null?
        return false;
    }
}

export function getQuotaColor(currentQuota: number, defaultQuota: number) {
    if (!currentQuota || currentQuota == 0) {
        return "#d32f2f"; //Red
    } else if (!defaultQuota || defaultQuota == 0) {
        return "#1976d2"; //Bleue
    } else if ((currentQuota * 100 / defaultQuota) > 30) {
        return "#388e3c"; //Green
    } else if ((currentQuota * 100 / defaultQuota) > 5) {
        return "#fbc02d"; //Yellow
    } else if ((currentQuota * 100 / defaultQuota) <= 5) {
        return "#d32f2f"; //Red
    }
}

export function getStatusColor(status) {
    switch (status) {
        //Green
        case RequestStatus[RequestStatus.Processed]:
        case RequestStatus[RequestStatus.Success]:
            return "#388e3c";

        //Bleue
        case RequestStatus[RequestStatus.Running]:
        case RequestStatus[RequestStatus.Information]:
        case RequestStatus[RequestStatus.PendingActivation]:
        case RequestStatus[RequestStatus.InProcess]:
            return "#1976d2";

        //Yellow
        case RequestStatus[RequestStatus.ReadyToProcess]:
        case RequestStatus[RequestStatus.ValidFile]:
        case RequestStatus[RequestStatus.Downloaded]:
        case RequestStatus[RequestStatus.Loaded]:
        case RequestStatus[RequestStatus.Warning]:
        case RequestStatus[RequestStatus.PendingProvisioning]:
            return "#fbc02d";

        //Red
        case RequestStatus[RequestStatus.Expired]:
        case RequestStatus[RequestStatus.InvalidFileContent]:
        case RequestStatus[RequestStatus.InvalidFileType]:
        case RequestStatus[RequestStatus.Invalid]:
        case RequestStatus[RequestStatus.Failure]:
        case RequestStatus[RequestStatus.Error]:
        case RequestStatus[RequestStatus.MaxRetriesReached]:
        case RequestStatus[RequestStatus.MaxWaitReached]:
        case RequestStatus[RequestStatus.Disabled]:
            return "#d32f2f";

        //Bleue
        default:
            return "#1976d2";
    }
};

export function dateToFormatedLocaleString(date) {
    if (!date) {
        return null;
    }
    return new Date(date).toLocaleString(navigator.language);
}

export function formatedLocaleStringToDate(dateString) {
    if (!dateString) {
        return null;
    }
    return new Date(dateString);
}

export function formatUserGroupsByEnv(userGroups: Array<String>, envName) {
    if (!userGroups || !envName) {
        return null;
    }

    var formatedUserGroups: Array<String> = new Array<String>();
    switch (envName) {
        case 'local':
        case 'dev':
            userGroups.forEach(userGroup => {
                formatedUserGroups.push(userGroup.substring(17, 23));
            });
            return formatedUserGroups;

        case 'pprod':
        case 'pprodTest':
            userGroups.forEach(userGroup => {
                formatedUserGroups.push(userGroup.substring(17, 23));
            });
            return formatedUserGroups;

        case 'prod':
        case 'prodTest':
            userGroups.forEach(userGroup => {
                formatedUserGroups.push(userGroup.substring(13));
            });
            return formatedUserGroups;

        default:
            return userGroups;
    }
}

export function formatFullName(firstName: String, lastName: String) {
    if (firstName && lastName) {
        return firstName + ' ' + lastName;
    }
    return null;
}

export function getTargetValueByProduct(element) {
    if (element.ProductName == 'Add-DistributionList-Owner' ||
        element.ProductName == 'Change-DistributionList-Owner' ||
        element.ProductName == 'Remove-DistributionList-Owner' ||
        element.ProductName == 'Add-DistributionList-Members' ||
        element.ProductName == 'Get-DistributionList-Members' ||
        element.ProductName == 'Set-DistributionList-Members' ||
        element.ProductName == 'Remove-DistributionList-Members' ||
        element.ProductName == 'Add-DistributionList-MailAddresses' ||
        element.ProductName == 'Remove-DistributionList-MailAddresses' ||
        element.ProductName == 'Add-DistributionList-DeliveryRestriction' ||
        element.ProductName == 'Remove-DistributionList-DeliveryRestriction' ||
        element.ProductName == 'Change-DistributionList-PrimarySMTP' ||
        element.ProductName == 'Remove-DistributionList-SendAs' ||
        element.ProductName == 'Hide-DistributionList' ||
        element.ProductName == 'UnHide-DistributionList' ||
        element.ProductName == 'Delete-DistributionList' ||
        element.ProductName == 'UnDelete-DistributionList' ||
        element.ProductName == 'Get-DistributionList-Info') {
        return element.Parameters && element.Parameters.LISTID ? Array.isArray(element.Parameters.LISTID) ? element.Parameters.LISTID.toString() : element.Parameters.LISTID : '';
    } else if (element.ProductName == 'Create-DistributionList' || element.ProductName == 'Manage-LocalITCard' || element.ProductName == 'Create-Customer-Profile' || element.ProductName == 'Update-Customer-Profile' || element.ProductName == 'Delete-Customer-Profile') {
        return element.Parameters && element.Parameters.NAME ? Array.isArray(element.Parameters.NAME) ? element.Parameters.NAME.toString() : element.Parameters.NAME : '';
    } else if (element.ProductName == 'Add-Okta-User-To-Group' || element.ProductName == 'Add-OktaB2B-User-To-Group' || element.ProductName == 'Remove-Okta-User-From-Group' || element.ProductName == 'Remove-OktaB2B-User-From-Group' || element.ProductName == 'Get-Okta-Group-Members' || element.ProductName == 'Get-OktaB2B-Group-Members') {
        return element.Parameters && element.Parameters.OKTAGROUPNAME ? Array.isArray(element.Parameters.OKTAGROUPNAME) ? element.Parameters.OKTAGROUPNAME.toString() : element.Parameters.OKTAGROUPNAME : '';
    } else if (element.ProductName == 'Create-AAD-Group' || element.ProductName == 'Create-AAD-DynamicGroup' || element.ProductName == 'Delete-AzureADGroup' || element.ProductName == 'Undelete-AzureADGroup') {
        return element?.Parameters?.GROUPDISPLAYNAME ? Array.isArray(element.Parameters.GROUPDISPLAYNAME) ? element.Parameters.GROUPDISPLAYNAME.toString() : element.Parameters.GROUPDISPLAYNAME : element?.Parameters?.IDENTITY || '';
    } else if (element.ProductName == 'Add-AAD-GroupMember' || element.ProductName == 'Remove-AAD-GroupMember') {
        return element.Parameters && element.Parameters.AADGROUPDISPLAYNAME ? Array.isArray(element.Parameters.AADGROUPDISPLAYNAME) ? element.Parameters.AADGROUPDISPLAYNAME.toString() : element.Parameters.AADGROUPDISPLAYNAME : '';
    } else if ((element.ProductName == 'Create-ResourceMailbox' || element.ProductName == 'Create-RoomMailbox') && (element.Parameters && !element.Parameters.GROUPID)) {
        return element.Parameters && element.Parameters.NAME ? Array.isArray(element.Parameters.NAME) ? element.Parameters.NAME.toString() : element.Parameters.NAME : '';
    } else if (element.ProductName == 'Reset-Guest-MFA' || element.ProductName == 'Delete-Guest-User') {
        return element.Parameters && element.Parameters.GUEST ? Array.isArray(element.Parameters.GUEST) ? element.Parameters.GUEST.toString() : element.Parameters.GUEST : '';
    } else if (element.ProductName == 'Create-Guest-User') {
        return element.Parameters && element.Parameters.GUESTEMAILADDRESS ? Array.isArray(element.Parameters.GUESTEMAILADDRESS) ? element.Parameters.GUESTEMAILADDRESS.toString() : element.Parameters.GUESTEMAILADDRESS : '';
    } else if (element.ProductName == 'Update-Guest-User') {
        return element.Parameters && element.Parameters.GUEST;
    } else if (element.ProductName == 'Zscaler-FireWall' || element.ProductName == 'Add-Zscaler-FireWall' || element.ProductName == 'Delete-Zscaler-FireWall') {
        return element.Parameters && element.Parameters.DESTINATIONNAME;
    } else if (element.ProductName == 'Zscaler-Bypass' || element.ProductName == 'Add-Zscaler-Bypass' || element.ProductName == 'Delete-Zscaler-Bypass') {
        return element.Parameters && element.Parameters.BYPASSTYPE;
    } else if (element.ProductName == 'Zscaler-Tunneling') {
        return element.Parameters && element.Parameters.ACTION;
    } else if (element.ProductName == 'Add-Zscaler-Tunneling' || element.ProductName == 'Delete-Zscaler-Tunneling') {
        return element.Parameters && element.Parameters.CATEGORYNAME;
    } else if (element.ProductName == 'Get-BitlockerKey' || element.ProductName == 'Get-LocalAdminPassword') {
        if(element.Parameters?.SERIAL) {
            return element.Parameters && element.Parameters.SERIAL ? Array.isArray(element.Parameters.SERIAL) ? element.Parameters.SERIAL.toString() : element.Parameters.SERIAL : '';
        } else {
            return element.Parameters && element.Parameters.DEVICE ? Array.isArray(element.Parameters.DEVICE) ? element.Parameters.DEVICE.toString() : element.Parameters.DEVICE : '';
        }
    } else if (element.ProductName == 'Create-OktaB2B-CloudAccount' || element.ProductName == 'Update-OktaB2B-CloudAccount' || element.ProductName == 'Reset-OktaB2B-CloudAccount-Password' || element.ProductName == 'Reactivate-OktaB2B-CloudAccount') {
        return element.Parameters && element.Parameters.OKTAUSERNAME ? Array.isArray(element.Parameters.OKTAUSERNAME) ? element.Parameters.OKTAUSERNAME.toString() : element.Parameters.OKTAUSERNAME : '';
    } else if (element.ProductName == 'Set-Office365RetentionPolicy') {
        return element.Parameters && element.Parameters.SITEURL ? Array.isArray(element.Parameters.SITEURL) ? element.Parameters.SITEURL.toString() : element.Parameters.SITEURL : '';
    } else {
        return element.Parameters && element.Parameters.GROUPID ? Array.isArray(element.Parameters.GROUPID) ? element.Parameters.GROUPID.toString() : element.Parameters.GROUPID : '';
    }
}

export function removeArrayEmptyValues(arrayToBeCleaned: Array<string>) {
    var arrayCleaned = new Array<string>();
    if (Array.isArray(arrayToBeCleaned)) {
        arrayToBeCleaned.forEach(stringValue => {
            if (stringValue && stringValue != '') {
                arrayCleaned.push(stringValue);
            }
        });
    }
    return arrayCleaned;
}

export const urlPattern = new RegExp('^((https?:\\/\\/)|(\\.))?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator

// this function try to guess productName according to the PEO pathname; if he can't, he return undefined.
export const guessingProductName = (): string | undefined => {
    const specialCodes = ["AAD"];
    const specialSkipCodes = ["Personal", "Cloud"];
    const skipSpecialCodes = (name:string) => {
        specialSkipCodes.forEach(code => name = name.replace(code, code.toUpperCase()));
        return name;
    }
    const addSpecialCodes = (name:string) => {
        specialCodes.forEach( code => name = name.replace(code, `-${code.toUpperCase()}-`) );
        return name;
    }
    const path = window.location.pathname;
    // console.log(path);
    if(path.includes("products") && path.split("/")[2]){
        let res = path.split("/")[2];
        res = skipSpecialCodes(res);
        res = addSpecialCodes(res);
        return res.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/[\s_]+/g, '-').toUpperCase();
    }
    return undefined;
}