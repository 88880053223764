import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '../../app/app.service';
import { UserService } from '../Services/user.service';
import { LocalITsService } from '../Services/localITs.service';
import { UserInfo } from '../Models/UserInfo';
import { HomeService } from '../Services/home.service';
import { NavItem } from '../../app/Helpers/nav-item';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  selectedLocalIT: string = null;
  currentUserInfo: UserInfo = new UserInfo();
  messageInfo: any;
  localITdetails;
  localITleftMenu: NavItem[] = [];
  localITrightMenu: NavItem[] = [];
  isAuthenticated: boolean;

  constructor(public oktaAuth: OktaAuthService, private userService: UserService, private localITsService: LocalITsService, private homeService: HomeService) { }

  ngOnInit() {
    this.oktaAuth.$isAuthenticated.subscribe(val => {
      this.isAuthenticated = val;
      if (val) {
        this.getData();
      }
    });
  }

  ngOnDestroy() {
  }

  onLocalITChange(localITNewValue) {
    this.selectedLocalIT = localITNewValue ? localITNewValue : '';
    this.userService.updateSelectedUserLocalIT(localITNewValue ? localITNewValue : '');
  }

  updateMenuDisplay() {
    let leftMenu: NavItem[] = [
      {
        displayName: "Okta",
        children: [
          {
            displayName: "B2E",
            children: [
              {
                displayName: "Cloud account",
                children: [
                  { displayName: "Create", route: "/products/createOktaCloudAccount", internalName: "CREATE-OKTA-CLOUDACCOUNT" },
                  { displayName: "Update", route: "/products/updateOktaCloudAccount", internalName: "UPDATE-OKTA-CLOUDACCOUNT" },
                  { displayName: "Reset password", route: "/products/resetOktaCloudAccountPassword", internalName: "RESET-OKTA-CLOUDACCOUNT-PASSWORD" },
                ]
              },
              {
                displayName: "Group",
                children: [
                  { displayName: "Get group members", route: "/products/getOktaGroupMembers", internalName: "GET-OKTA-GROUP-MEMBERS" },
                  { displayName: "Add user to group", route: "/products/addOktaUserToGroup", internalName: "ADD-OKTA-USER-TO-GROUP" },
                  { displayName: "Remove user from group", route: "/products/removeOktaUserFromGroup", internalName: "REMOVE-OKTA-USER-FROM-GROUP" },
                ]
              },
              {
                displayName: "Clear user session", route: "/products/clearOktaUserSession", internalName: "CLEAR-OKTA-USER-SESSION"
              },
              {
                displayName: "Suspend user", route: "/products/suspendOktaUser", internalName: "SUSPEND-OKTA-USER"
              },
              {
                displayName: "Unlock user", route: "/products/unlockOktaUser", internalName: "UNLOCK-OKTA-USER"
              },
              {
                displayName: "Unsuspend user", route: "/products/unsuspendOktaUser", internalName: "UNSUSPEND-OKTA-USER"
              },
              {
                displayName: "MFA",
                children: [
                  { displayName: "Reset MFA", route: "/products/resetOktaUserMFA", internalName: "RESET-OKTA-USER-MFA" },
                  { displayName: "Bypass MFA", route: "/products/bypassOktaMFA", internalName: "BYPASS-OKTA-MFA" },
                  { displayName: "Exclude", route: "/products/excludeOktaUserMFA", internalName: "EXCLUDE-OKTA-USER-MFA" }
                ]
              },
              { displayName: "Get assigned app", route: "/products/GetOktaAssignedApp", internalName: "GET-OKTA-ASSIGNEDAPP" },
            ]
          },
          {
            displayName: "B2B",
            children: [
              {
                displayName: "Cloud account",
                children: [
                  { displayName: "Create", route: "/products/createOktaB2BCloudAccount", internalName: "CREATE-OKTAB2B-CLOUDACCOUNT" },
                  { displayName: "Update", route: "/products/updateOktaB2BCloudAccount", internalName: "UPDATE-OKTAB2B-CLOUDACCOUNT" },
                  { displayName: "Reactivate", route: "/products/reactivateOktaB2BCloudAccount", internalName: "REACTIVATE-OKTAB2B-CLOUDACCOUNT" },
                  { displayName: "Reset password", route: "/products/resetOktaB2BCloudAccountPassword", internalName: "RESET-OKTAB2B-CLOUDACCOUNT-PASSWORD" }
                ]
              },
              {
                displayName: "Group",
                children: [
                  { displayName: "Get group members", route: "/products/getOktaB2BGroupMembers", internalName: "GET-OKTAB2B-GROUP-MEMBERS" },
                  { displayName: "Add user to group", route: "/products/addOktaB2BUserToGroup", internalName: "ADD-OKTAB2B-USER-TO-GROUP" },
                  { displayName: "Remove user from group", route: "/products/removeOktaB2BUserFromGroup", internalName: "REMOVE-OKTAB2B-USER-FROM-GROUP" }
                ]
              },
              { displayName: "Clear user session", route: "/products/clearOktaB2BUserSession", internalName: "CLEAR-OKTAB2B-USER-SESSION" },
              { displayName: "Suspend user", route: "/products/suspendOktaB2BUser", internalName: "SUSPEND-OKTAB2B-USER" },
              { displayName: "Unlock user", route: "/products/unlockOktaB2BUser", internalName: "UNLOCK-OKTAB2B-USER" },
              { displayName: "Unsuspend user", route: "/products/unsuspendOktaB2BUser", internalName: "UNSUSPEND-OKTAB2B-USER" },
              {
                displayName: "MFA",
                children: [
                  { displayName: "Reset MFA", route: "/products/resetOktaB2BUserMFA", internalName: "RESET-OKTAB2B-USER-MFA" },
                ]
              },
            ]
          },
        ]
      },
      {
        displayName: "Azure AD",
        children: [
          {
            displayName: "Azure AD group",
            children: [
              { displayName: "Create", route: "/products/createAADGroup", internalName: "CREATE-AAD-GROUP" },
              { displayName: "Delete", route: "/products/deleteAzureADGroup", internalName: "DELETE-AZUREADGROUP" },
              { displayName: "Undelete", route: "/products/undeleteAzureADGroup", internalName: "UNDELETE-AZUREADGROUP" },
              { displayName: "Add member", route: "/products/addAADGroupMember", internalName: "ADD-AAD-GROUPMEMBER" },
              { displayName: "Remove member", route: "/products/removeAADGroupMember", internalName: "REMOVE-AAD-GROUPMEMBER" }
            ]
          },
          {
            displayName: "Azure AD dynamic group",
            children: [
              { displayName: "Create", route: "/products/createAADDynamicGroup", internalName: "CREATE-AAD-DYNAMICGROUP" }
            ]
          },
          {
            displayName: "Guest user",
            children: [
              { displayName: "Create", route: "/products/createGuestUser", internalName: "CREATE-GUEST-USER" },
              { displayName: "Update", route: "/products/updateGuestUser", internalName: "UPDATE-GUEST-USER" },
              { displayName: "Delete", route: "/products/deleteGuestUser", internalName: "DELETE-GUEST-USER" },
              { displayName: "Reset MFA", route: "/products/resetGuestMFA", internalName: "RESET-GUEST-MFA" },
            ]
          },
        ]
      },
      {
        displayName: "Exchange",
        children: [
          {
            displayName: "Mailbox",
            children: [
              { displayName: "Get mailbox info", route: "/products/getMailboxInfo", internalName: "GET-MAILBOX-INFO" },
              {
                displayName: "Create", children: [
                  { displayName: "Personal mailbox", route: "/products/createPersonalMailbox", internalName: "CREATE-PERSONALMAILBOX" },
                  { displayName: "Resource Mailbox", route: "/products/createResourceMailbox", internalName: "CREATE-RESOURCEMAILBOX" },
                  { displayName: "Shared mailbox", route: "/products/createSharedMailbox", internalName: "CREATE-SHAREDMAILBOX" },
                  { displayName: "Admin mailbox", route: "/products/createAdminMailbox", internalName: "CREATE-ADMINMAILBOX" },
                  { displayName: "Anonymous mailbox", route: "/products/createAnonymousMailbox", internalName: "CREATE-ANONYMOUSMAILBOX" },
                  { displayName: "Application mailbox", route: "/products/createApplicationMailbox", internalName: "CREATE-APPLICATIONMAILBOX" },
                  { displayName: "MEU", route: "/products/createMEU", internalName: "CREATE-MEU" }
                ]
              },
              {
                displayName: "Mail addresses", children: [
                  { displayName: "Add", route: "/products/addMailboxMailAddresses", internalName: "ADD-MAILBOX-MAILADDRESSES" },
                  { displayName: "Remove", route: "/products/removeMailboxMailAddresses", internalName: "REMOVE-MAILBOX-MAILADDRESSES" },
                  { displayName: "Change primary SMTP", route: "/products/changeMailboxPrimarySMTP", internalName: "CHANGE-MAILBOX-PRIMARYSMTP" }
                ]
              },
              {
                displayName: "Litigation hold", children: [
                  { displayName: "Enable", route: "/products/enableLitigationHold", internalName: "ENABLE-LITIGATIONHOLD" },
                  { displayName: "Disable", route: "/products/disableLitigationHold", internalName: "DISABLE-LITIGATIONHOLD" }
                ]
              },
              {
                displayName: "Delegation", children: [
                  { displayName: "Add", route: "/products/addMailboxDelegation", internalName: "ADD-MAILBOX-DELEGATION" },
                  { displayName: "Remove", route: "/products/removeMailboxDelegation", internalName: "REMOVE-MAILBOX-DELEGATION" }
                ]
              },
              {
                displayName: "Manager", children: [
                  { displayName: "Change", route: "/products/changeMailboxManager", internalName: "CHANGE-MAILBOX-MANAGER" }
                ]
              },
              {
                displayName: "Archive online", children: [
                  { displayName: "Enable", route: "/products/enableArchiveOnline", internalName: "ENABLE-ARCHIVEONLINE" }
                ]
              },
              {
                displayName: "Visibility", children: [
                  { displayName: "Hide", route: "/products/hideMailbox", internalName: "HIDE-MAILBOX" },
                  { displayName: "UnHide", route: "/products/unHideMailbox", internalName: "UNHIDE-MAILBOX" }
                ]
              },
              {
                displayName: "Display name", children: [
                  { displayName: "Change", route: "/products/changeMailboxDisplayName", internalName: "CHANGE-MAILBOX-DISPLAYNAME" }
                ]
              },
              {
                displayName: "Outlook places", children: [
                  { displayName: "Set", route: "/products/setOutlookPlaces", internalName: "SET-OUTLOOK-PLACES" }
                ]
              },
            ]
          },
          {
            displayName: "Distribution list",
            children: [
              { displayName: "Get distributionlist info", route: "/products/getDistributionListInfo", internalName: "GET-DISTRIBUTIONLIST-INFO" },
              { displayName: "Create", route: "/products/createDistributionList", internalName: "CREATE-DISTRIBUTIONLIST" },
              { displayName: "Delete", route: "/products/deleteDistributionList", internalName: "DELETE-DISTRIBUTIONLIST" },
              { displayName: "UnDelete", route: "/products/undeleteDistributionList", internalName: "UNDELETE-DISTRIBUTIONLIST" },
              {
                displayName: "Members", children: [
                  { displayName: "Add", route: "/products/addDistributionListMembers", internalName: "ADD-DISTRIBUTIONLIST-MEMBERS" },
                  { displayName: "Get", route: "/products/getDistributionListMembers", internalName: "GET-DISTRIBUTIONLIST-MEMBERS" },
                  { displayName: "Set", route: "/products/setDistributionListMembers", internalName: "SET-DISTRIBUTIONLIST-MEMBERS" },
                  { displayName: "Remove", route: "/products/removeDistributionListMembers", internalName: "REMOVE-DISTRIBUTIONLIST-MEMBERS" }
                ]
              },
              {
                displayName: "Owner", children: [
                  { displayName: "Add", route: "/products/addDistributionListOwner", internalName: "ADD-DISTRIBUTIONLIST-OWNER" },
                  { displayName: "Change", route: "/products/changeDistributionListOwner", internalName: "CHANGE-DISTRIBUTIONLIST-OWNER" },
                  { displayName: "Remove", route: "/products/removeDistributionListOwner", internalName: "REMOVE-DISTRIBUTIONLIST-OWNER" }
                ]
              },
              {
                displayName: "Mail addresses", children: [
                  { displayName: "Add", route: "/products/addDistributionListMailAddresses", internalName: "ADD-DISTRIBUTIONLIST-MAILADDRESSES" },
                  { displayName: "Remove", route: "/products/removeDistributionListMailAddresses", internalName: "REMOVE-DISTRIBUTIONLIST-MAILADDRESSES" },
                  { displayName: "Change primary SMTP", route: "/products/changeDistributionListPrimarySMTP", internalName: "CHANGE-DISTRIBUTIONLIST-PRIMARYSMTP" }
                ]
              },
              {
                displayName: "Send as", children: [
                  { displayName: "Remove", route: "/products/removeDistributionListSendAs", internalName: "REMOVE-DISTRIBUTIONLIST-SENDAS" }
                ]
              },
              {
                displayName: "Visibility", children: [
                  { displayName: "Hide", route: "/products/hideDistributionList", internalName: "HIDE-DISTRIBUTIONLIST" },
                  { displayName: "UnHide", route: "/products/unHideDistributionList", internalName: "UNHIDE-DISTRIBUTIONLIST" }
                ]
              },
              {
                displayName: "Delivery restriction", children: [
                  { displayName: "Add", route: "/products/addDistributionListDeliveryRestriction", internalName: "ADD-DISTRIBUTIONLIST-DELIVERYRESTRICTION" },
                  { displayName: "Remove", route: "/products/removeDistributionListDeliveryRestriction", internalName: "REMOVE-DISTRIBUTIONLIST-DELIVERYRESTRICTION" }
                ]
              }
            ]
          },
          { displayName: "Transfer employee", route: "/products/transferEmployee", internalName: "TRANSFER-EMPLOYEE" },
          {
            displayName: "Mobile device",
            children: [
              { displayName: "Enable mobile device", route: "/products/enableMobileDevice", internalName: "ENABLE-MOBILEDEVICE" }
            ]
          }
        ]
      },
      {
        displayName: "Office 365",
        children: [
          { displayName: "Get user info", route: "/products/getUserInfo", internalName: "GET-USER-INFO" },
          {
            displayName: "Data Lifecycle Management", children: [
              { displayName: "Set Retention Policy", route: "/products/setOffice365RetentionPolicy", internalName: "SET-OFFICE365RETENTIONPOLICY" }
            ]
          },
          {
            displayName: "License", children: [
              { displayName: "Get", route: "/products/GetLicensesDetails", internalName: "GET-LICENSESDETAILS" },
              { displayName: "Assign", route: "/products/assignLicense", internalName: "ASSIGN-LICENSE" },
              { displayName: "Remove", route: "/products/removeLicense", internalName: "REMOVE-LICENSE" },
              { displayName: "Change plans", route: "/products/ChangeLicensePlans", internalName: "CHANGE-LICENSEPLANS" }
            ]
          },
          {
            displayName: "O365 object", children: [
              { displayName: "Delete", route: "/products/deleteO365Object", internalName: "DELETE-O365OBJECT" },
              { displayName: "Undelete", route: "/products/undeleteO365Object", internalName: "UNDELETE-O365OBJECT" },
              { displayName: "Block user", route: "/products/blockUser", internalName: "BLOCK-USER" },
              { displayName: "Enable user", route: "/products/enableUser", internalName: "ENABLE-USER" },
              { displayName: "Reset user token", route: "/products/resetUserToken", internalName: "RESET-USERTOKEN" }
            ]
          },
          { displayName: "Get Identity Personal Data", route: "/products/getIdentityPersonalData", internalName: "GET-IDENTITYPERSONALDATA" },
        ]
      },
      {
        displayName: "Network",
        children: [
          {
            displayName: "Zscaler bypass", children:
              [
                { displayName: "Add", route: "/products/addZscalerBypass", internalName: "ADD-ZSCALER-BYPASS" },
                { displayName: "Delete", route: "/products/deleteZscalerBypass", internalName: "DELETE-ZSCALER-BYPASS" }
              ]
          },
          {
            displayName: "Zscaler tunneling", children:
              [
                { displayName: "Add", route: "/products/addZscalerTunneling", internalName: "ADD-ZSCALER-TUNNELING" },
                { displayName: "Delete", route: "/products/deleteZscalerTunneling", internalName: "DELETE-ZSCALER-TUNNELING" }
              ]
          },
          {
            displayName: "Zscaler firewall", children:
              [
                { displayName: "Add", route: "/products/addZscalerFirewall", internalName: "ADD-ZSCALER-FIREWALL" },
                { displayName: "Delete", route: "/products/deleteZscalerFirewall", internalName: "DELETE-ZSCALER-FIREWALL" }
              ]
          },
          { displayName: "Public DNS", route: "/products/publicDns", internalName: "PUBLIC-DNS" },
        ]
      },
      {
        displayName: "Teams",
        children: [
          {
            displayName: "Phone system",
            children: [
              {
                displayName: "Enable", children: [
                  { displayName: "Teams common area phone", route: "/products/enableTeamsCAPPhone", internalName: "ENABLE-TEAMS-CAPPHONE" },
                  { displayName: "Teams phone system", route: "/products/enableTeamsPhoneSystem", internalName: "ENABLE-TEAMS-PHONESYSTEM" },
                ]
              },
              {
                displayName: "Remove", children: [
                  { displayName: "Teams phone system", route: "/products/removeTeamsPhoneSystem", internalName: "REMOVE-TEAMS-PHONESYSTEM" },
                ]
              }
            ]
          },
          {
            displayName: "Room mailbox",
            children: [
              { displayName: "Enable", route: "/products/enableTeamsRoomMailbox", internalName: "ENABLE-TEAMS-ROOMMAILBOX" },
            ]
          },
        ]
      },
      {
        displayName: "Endpoint management",
        children: [
          {
            displayName: "Intune",
            children: [
              {
                displayName: "Intune user",
                children: [
                  { displayName: "Add", route: "/products/addIntuneUser", internalName: "ADD-INTUNE-USER" },
                  { displayName: "Modify", route: "/products/modifyIntuneUser", internalName: "MODIFY-INTUNE-USER" },
                  { displayName: "Remove", route: "/products/removeIntuneUser", internalName: "REMOVE-INTUNE-USER" }
                ]
              }
            ]
          },
          {
            displayName: "Modern workplace",
            children: [
              { displayName: "Add user", route: "/products/addMWUser", internalName: "ADD-MW-USER" },
              { displayName: "Get bitlocker key", route: "/products/getBitlockerKey", internalName: "GET-BITLOCKERKEY" },
              { displayName: "Get Local Admin Password", route: "/products/getLocalAdminPassword", internalName: "GET-LOCALADMINPASSWORD" },
              { displayName: "Set User Ring", route: "/products/setUserRing", internalName: "SET-USERRING" }
            ]
          },
          { displayName: "Remove device", route: "/products/removeAzureADDevice", internalName: "REMOVE-AZUREADDEVICE" }
        ]
      },
      {
        displayName: "AllocID",
        children: [
          {
            displayName: "Access management",
            children: [
              { displayName: "Add", route: "/products/addAllocIDPermission", internalName: "ADD-ALLOCID-PERMISSION" },
              { displayName: "Revoke", route: "/products/revokeAllocIDPermission", internalName: "REVOKE-ALLOCID-PERMISSION" }
            ]
          },
          {
            displayName: "GID administration",
            children: [
              { displayName: "Activate", route: "/products/activateGID", internalName: "ACTIVATE-GID" },
              { displayName: "Create", route: "/products/createGID", internalName: "CREATE-GID" },
              { displayName: "Deactivate", route: "/products/deactivateGID", internalName: "DEACTIVATE-GID" },
              { displayName: "Update", route: "/products/updateAllocIDData", internalName: "UPDATE-ALLOCID-DATA" }
            ]
          }
        ]
      },
    ];
    let persistantleftMenu: NavItem[] = [
    ]
    let rightMenu: NavItem[] = [
      {
        displayName: "Admin",
        children: [
          { displayName: "Manage admin users", route: "/administration/manageAdminUsers" },
          {
            displayName: "Configuration",
            children: [
              { displayName: "Top bar messages", route: "/administration/topBarMessages" }
            ]
          }
        ]
      }
    ]
    let persistantrightMenu: NavItem[] = [
      { displayName: "CSV Import", route: "/csvImport" },
      { displayName: "History", route: "/history" },
      {
        displayName: "Resources",
        children: [
          { displayName: "Local IT cards", route: "/localItCards" },
          { displayName: "Licenses report", route: "/licensesReport" },
          { displayName: "Customer profiles", route: "/customerProfiles" },
          { displayName: "Knowledge base", href: "https://engie.sharepoint.com/sites/ensemblesupport/knowledgebase/Pages/ENS0408_PEO_next.aspx" },
          { displayName: "Dashboard", href: "https://app.powerbi.com/Redirect?action=OpenApp&appId=b4e879ea-f6fd-4ff1-b733-0d3cf15195d3&ctid=24139d14-c62c-4c47-8bdd-ce71ea1d50cf" }
        ]
      },
    ]

    let localITleftMenu = [];
    let localITrightMenu = [];

    if (this.localITdetails) {
      if (this.localITdetails.Products) {
        leftMenu.forEach(menu => {
          if (menu.children) {
            menu.children = this.childrenMenu(menu.children)
          }
          if (menu.children == null) {
            delete menu["children"];
          }
          if (menu.children || menu.route || menu.href) {
            localITleftMenu.push(menu);
          }
        });
      }
    }
    if ((this.localITdetails && this.localITdetails.Name && this.localITdetails.Name.length > 0) || this.selectedLocalIT === 'SHOW ALL') {
      persistantleftMenu.forEach(menu => {
        localITleftMenu.push(menu);
      });
      if(this.selectedLocalIT !== 'SHOW ALL' && this.localITdetails.Products && this.localITdetails.Products["DELETE-O365OBJECT"]){
        persistantrightMenu.unshift({ displayName: "License Cleanup", route: "/licenseCleanup" });
      }
      persistantrightMenu.forEach(menu => {
        localITrightMenu.push(menu);
      });
    }
    if (this.currentUserInfo.IsAdmin && ((this.localITdetails && this.localITdetails.Name && this.localITdetails.Name.length > 0) || this.selectedLocalIT === 'SHOW ALL')) {
      rightMenu.forEach(menu => {
        localITrightMenu.push(menu);
      });
    }

    this.localITleftMenu = localITleftMenu;
    this.localITrightMenu = localITrightMenu;
  }

  childrenMenu(submenu): NavItem[] {
    let toRemove: Array<number> = [];
    submenu.forEach(element => {
      if (element.children) {
        element.children = this.childrenMenu(element.children)
      }

      if (element.internalName) {
        if (!this.localITdetails.Products[element.internalName] || this.localITdetails.Products[element.internalName] == false || (this.localITdetails.Pilot == false && this.localITdetails.Products[element.internalName] == "PILOT")) {
          toRemove.push(submenu.indexOf(element));
        }
      }
      if (element.children == null) {
        delete element["children"];
      }
      if (!element.length && !element.children && !element.route && !element.href) {
        toRemove.push(submenu.indexOf(element));
      }
    });
    if (toRemove.length > 0) {
      toRemove.sort((a, b) => a - b)
      toRemove.reverse();
      toRemove.forEach(element => {
        submenu.splice(element, 1);
      });
      toRemove = [];
    }

    if (submenu.length < 1 && !submenu.children) {
      return null;
    }

    return submenu;
  }

  getData() {
    this.homeService.getMessageInfo().subscribe(response => {
      this.messageInfo = response;
    });
    this.localITsService.getLocalITsWithShowAllPermission()
      .subscribe(async response => {
        this.userService.localITsAllowedToShowAllRequests = response;
        this.userService.updateAccessTokenPayload(await this.oktaAuth.getAccessToken());
      });
    this.userService.currentUserInfoObs.subscribe(updatedUserInfo => {
      this.currentUserInfo = updatedUserInfo;
      this.onLocalITChange(updatedUserInfo.LocalITs ? updatedUserInfo.LocalITs[0] : null);
      this.userService.selectedUserLocalITCardObs.subscribe(updatedLocalITDetails => {
        this.localITdetails = updatedLocalITDetails;
        this.updateMenuDisplay();
      });
    });
  }
}
